import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUIDUtil } from '@gipisistemas/ng-core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { ArrayUtil, DateUtil, GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService, GIPIPageModel, GIPIUuid, NumberUtil, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';

import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialBillStatusEnum } from '../enums/bill-status.enum';
import { FinancialBillInstallmentRenegotiation } from '../models/bill-installment-renegotiation.model';
import { FinancialBillInstallment } from '../models/bill-installment.model';
import { FinancialBillInstallmentConsultDTO } from '../models/dto/bill-installment-consult.dto';
import { FinancialBillInstallmentSummaryDTO } from '../models/dto/bill-installment-summary.dto';
import { FinancialBillFilterDTO } from '../models/dto/bill.filter.dto';

@Injectable({ providedIn: 'root' })
export class FinancialBillInstallmentService extends GIPIAbstractCrudService<FinancialBillInstallmentConsultDTO, FinancialBillFilterDTO> {

    constructor(
        protected baseService: GIPIBaseService,
        private _receivementService: FinancialReceivementService,
        private _authenticationService: CustomAuthenticationService,
    ) {
        super(FinancialPaths.billInstallment, 'v1', baseService);
    }

    isValid(entity: FinancialBillInstallment, ignoreClientOrProvider: boolean = false): string {
        if (StringUtil.isEmpty(entity.description)) {
            return ('Campo descrição é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(entity.bankAccount)) {
            return ('Campo conta bancária é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(entity.postingCategory)) {
            return ('Campo categoria é obrigatório e não foi informado');
        }
        if (!ignoreClientOrProvider) {
            if ((entity.bill.type === 'RECEIVABLE' && (ObjectUtil.isNewModel(entity.bill.client) || ObjectUtil.isNull(entity.bill.client)))) {
                return ('Campo cliente é obrigatório e não foi informado');
            }
            if ((entity.bill.type === 'PAYABLE' && (ObjectUtil.isNewModel(entity.bill.provider) || ObjectUtil.isNull(entity.bill.provider)))) {
                return ('Campo fornecedor é obrigatório e não foi informado');
            }
        }
        if (ObjectUtil.isNewModel(entity.chargeType) || ObjectUtil.isNull(entity.chargeType)) {
            return ('Campo tipo de cobrança é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNull(entity.dueDate)) {
            return ('Campo data de vencimento é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNull(entity.issuanceDate)) {
            return ('Campo data de emissão é obrigatório e não foi informado');
        }
        if (!NumberUtil.isPositive(entity.amount)) {
            return ('Campo valor é obrigatório e não foi informado');
        }

        const dueDate: Date = new Date(moment(entity.dueDate).format('yyyy/MM/DD'));
        const issuanceDate: Date = new Date(entity.issuanceDate);
        if (DateUtil.isLessThan(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()),
            new Date(issuanceDate.getFullYear(), issuanceDate.getMonth(), issuanceDate.getDate()))) {
            return ('Data de vencimento não pode ser menor que a data de emissão');
        }

        if ((entity.bill.type === 'RECEIVABLE') && (entity.chargeType.type === 'BANK_SLIP') && (entity.bankAccount.type !== 'CHECKING_ACCOUNT')) {
            return ('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
        }

        const validateAssessment: string = this.validateAssessment(entity);
        if (!StringUtil.isEmpty(validateAssessment)) {
            return validateAssessment;
        }

        this.loadStatus(entity);

        return '';
    }

    validateAssessment(entity: FinancialBillInstallment): string {
        if (!ArrayUtil.isEmpty(entity.assessmentList) && entity.assessmentList.reduce((a, b) => +a + b.percentage, 0) < 100) {
            return ('Verifique o rateio para o centro de custo, ele não está completo');
        }
        return '';
    }

    loadStatus(entity: FinancialBillInstallment): void {
        if (entity.bill.type === 'RECEIVABLE' && ObjectUtil.isNewModel(entity) && StringUtil.isEmpty(entity.status)) {
            entity.status = 'RECEIVABLE';
        } else if (entity.bill.type === 'PAYABLE' && ObjectUtil.isNewModel(entity) && StringUtil.isEmpty(entity.status)) {
            entity.status = 'PAYABLE';
        }
    }

    findByBillId(billId: GIPIUuid, page: number = 0, status: FinancialBillStatusEnum[] = []): Observable<FinancialBillInstallment[]> {
        let lStatus: string = '';
        if (!ArrayUtil.isEmpty(status)) {
            const keysEnum: string[] = Object.keys(FinancialBillStatusEnum);
            const result: string[] = [];

            for (let i: number = 0; i < status.length; i++) {
                result.push(keysEnum.find(key => FinancialBillStatusEnum[key] === status[i]));
            }

            lStatus = '&status=' + result.join(',');
        }

        return this.baseService.httpClient.get(this.url(`find-by-bill/${billId}?page=${page}&size=100${lStatus}`), this.options()).pipe(
            map(json => {
                const page: PageDTO<FinancialBillInstallment> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }
                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByIdIn(idList: GIPIUuid[]): Observable<FinancialBillInstallment[]> {
        return this.baseService.httpClient.post(this.url('find-by-ids'), idList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findAll<F extends GIPIAbstractFilterModel = any, T = any>(filter?: F, version?: string): Observable<GIPIPageModel<T>> {
        return this.baseService.httpClient.post(this.url('find-all', version), filter, this.options()).pipe(
            map(json => {
                const page: GIPIPageModel<FinancialBillInstallmentConsultDTO> = this.handleMapper(json);

                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    const date: Date = new Date();
                    const compareDate: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

                    for (let i = 0; i < page.content.length; i++) {
                        const dueDate: Date = new Date(moment(page.content[i].dueDate).format('yyyy/MM/DD'));
                        const isLessThan: boolean = DateUtil.isLessThan(
                            new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()),
                            new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate())
                        );

                        if (isLessThan && page.content[i].status === 'RECEIVABLE') {
                            page.content[i].status = 'RECEIVABLE_OVERDUE';
                        } else if (isLessThan && page.content[i].status === 'PAYABLE') {
                            page.content[i].status = 'PAYABLE_OVERDUE';
                        }
                        if (page.content[i].status === 'RENEGOTIATED' || page.content[i].status === 'GROUPED') {
                            page.content[i].amount = 0;
                        }
                    }
                }

                return page;
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByDocumentNumberIn(idList: any[]): Observable<FinancialBillInstallment[]> {
        return this.baseService.httpClient.post(this.url('find-by-document-numbers'), idList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    updateBillInstallmentList(billInstallmentList: FinancialBillInstallment[]): Observable<FinancialBillInstallment[]> {
        return this.baseService.httpClient.put(this.url('update-bill-installments'), billInstallmentList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    getBillInstallmentGroupedList(billInstallmentId: GIPIUuid): Observable<FinancialBillInstallment[]> {
        return this.baseService.httpClient.get(this.url(`find-installment-grouped-by-id/${billInstallmentId}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    getDashboardSummary(): Observable<FinancialBillInstallmentSummaryDTO> {
        let httpClientParams = new HttpParams();
        const currentCompany: GIPIUuid = this._authenticationService.token.user.currentCompany;
        if (UUIDUtil.isValid(currentCompany)) {
            httpClientParams = httpClientParams.append('companyId', String(currentCompany));
        }
        return this.baseService.httpClient.get(this.url('dashboard-summary'), this.options(httpClientParams)).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findAllNotReconciledByCurrentCompany(): Observable<FinancialBillInstallment[]> {
        // let baseService.httpClientParams = new HttpParams();
        // const currentCompany = this.authenticationService.token.user.currentCompany;
        // if (NumberUtil.isPositive(currentCompany)) {
        //     baseService.httpClientParams = baseService.httpClientParams.append('companyId', String(currentCompany));
        // }
        // return this.baseService.httpClient.get(this.url(`find-all-not-reconciled`), this.options(baseService.httpClientParams)).pipe(
        //     map(this.handleMapper),
        //     catchError(this.handleError)
        // );

        // Desativar temporariamente pois está derrubando a API ao realizar a consulta das contas.
        return of([]);
    }

    groupInstallment(billInstallmentList: FinancialBillInstallment[]): Observable<any> {
        return this.baseService.httpClient.post(this.url('group-installments'), billInstallmentList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    isReceipt(billInstallmentId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._receivementService.findByBillInstallment(billInstallmentId).subscribe(receivement => {
                resolve(receivement.length > 0);
            });
        });
    }

    generateRenegotiationList(billInstallmentNewList: FinancialBillInstallment[], billInstallmentOldList: FinancialBillInstallment[], userLoggedId: GIPIUuid) {
        const billInstallmentRenegotiation: FinancialBillInstallmentRenegotiation = new FinancialBillInstallmentRenegotiation();
        billInstallmentRenegotiation.userId = userLoggedId;

        billInstallmentOldList.forEach(installment => {
            billInstallmentRenegotiation.renegotiatedBillInstallment.push(Number(installment.id));
        });

        if (billInstallmentNewList.length > 1) {
            billInstallmentNewList.forEach(installment => {
                installment.bill.billInstallmentRenegotiation = billInstallmentRenegotiation;
            });
        } else {
            billInstallmentRenegotiation.renegotiatedBillInstallment.push(Number(billInstallmentOldList[0].id));
            billInstallmentNewList[0].bill.billInstallmentRenegotiation = billInstallmentRenegotiation;
        }

        return Promise.resolve();
    }

    sendNotificationByListId(idList: GIPIUuid[]): Observable<any> {
        return this.baseService.httpClient.post(this.url('send-notification-by-list-id'), idList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
