import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '@gipi-environment/environment';
import { AuthenticationService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/material/dialog";
export class CustomAuthenticationService extends AuthenticationService {
    constructor(router, http, matDialog) {
        super(router, http, matDialog);
        this.router = router;
        this.http = http;
        this.matDialog = matDialog;
    }
    buildHeaders() {
        return new HttpHeaders()
            .append('Authorization', 'Basic ' + btoa(`${environment.client}:${environment.secret}`))
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }
    changeCompany(companyId) {
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', this.token.refreshToken)
            .set('currentCompany', String(companyId));
        return this.http.post('api/authenticator/oauth/token', body, this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))).pipe(map(token => this.setSession(token)));
    }
    logout() {
        this.revokeToken(this.token).subscribe(() => {
            this.removeSession();
            location.reload();
            sessionStorage.clear();
        });
    }
    setSession(token) {
        return super.setSession(token);
    }
}
CustomAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomAuthenticationService_Factory() { return new CustomAuthenticationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.MatDialog)); }, token: CustomAuthenticationService, providedIn: "root" });
