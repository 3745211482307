import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { BaseModel } from '@gipi-shared/models/base.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialUnlockBlockedClient } from '../enums/unlock-blocked-client.enum';

export class FinancialConfiguration extends BaseModel {

    /** Baixa automática do crédito do cliente */
    automateCreditRebate: boolean;

    /** Bloquear clientes negativados */
    blockNegativeClients: boolean;

    /** Categoria (Crédito) */
    defaultClientCreditCategoryToCredit: FinancialPostingCategory;

    /** Categoria (Débito) */
    defaultClientCreditCategoryToDebit: FinancialPostingCategory;

    /** Cliente padrão para vendas */
    defaultClientForSales: FinancialClient;

    /** Multa por atraso (%) */
    fine: number;

    /** Dias de carência */
    graceDays: number;

    /** Juros por atraso (%) */
    interest: number;

    /** Categoria acréscimo (Crédito) */
    postingCategoryForAdditionToCredit: FinancialPostingCategory;

    /** Categoria acréscimo (Débito) */
    postingCategoryForAdditionToDebit: FinancialPostingCategory;

    /** Imprimir parcelas pendentes no recebimento */
    printPendingInstallmentsOnReceipt: boolean;

    /** Mostrar nome fantasia nas consultas */
    showBusinessNameInReceiptQuery: boolean;

    /** Mostrar clientes desativados em pesquisas */
    showsDisabledCustomerInSearch: boolean;

    printReceiptAfterReceivement: boolean;

    /** Desbloquear os clientes bloqueados */
    unlockBlockedClient: FinancialUnlockBlockedClient;

    /** Dias p/ negativação */
    daysToNegative: number;

    /** Categoria para recebimentos com cartão */
    postingCategoryForReceivementCardAdministrator: FinancialPostingCategory;

    financialConfigurationId: GIPIUuid;

}
