import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@gipi-environment/environment';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { AuthenticationService, GIPIUuid, TokenDTO } from '@gipisistemas/ng-core';

@Injectable({ providedIn: 'root' })
export class CustomAuthenticationService extends AuthenticationService<OAuthUser> {

    constructor(
        protected router: Router,
        protected http: HttpClient,
        protected matDialog: MatDialog,
    ) {
        super(router, http, matDialog);
    }

    protected buildHeaders(): HttpHeaders {
        return new HttpHeaders()
            .append('Authorization', 'Basic ' + btoa(`${environment.client}:${environment.secret}`))
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }

    public changeCompany(companyId: GIPIUuid): Observable<TokenDTO<OAuthUser>> {
        const body: HttpParams = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', this.token.refreshToken)
            .set('currentCompany', String(companyId));

        return this.http.post<TokenDTO<OAuthUser>>(
            'api/authenticator/oauth/token',
            body,
            this.options(this.buildParams(), this.buildHeaders().append('skip', String(true)))
        ).pipe(
            map(token => this.setSession(token))
        );
    }

    public logout(): void {
        this.revokeToken(this.token).subscribe(() => {
            this.removeSession();
            location.reload();
            sessionStorage.clear();
        });
    }

    public setSession(token: TokenDTO<OAuthUser>): TokenDTO<OAuthUser> {
        return super.setSession(token);
    }

}
