<gipi-abstract-form id="dialogIntegrations"
                    name="dialogIntegrations"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogIntegrations">
        Integração do WhatsApp
    </gipi-toolbar>
    
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
         
         
         
         <div fxLayout="column"
             fxLayoutGap="16px">
            
            
             <!--
             <div id="panelTwilioInfoInvoice"
                 class="content-information">
                <label for="contentInformation">
                    Informações da fatura

                    <button id="btnIsClosedInvoiceTwilio"
                            name="btnIsClosedInvoiceTwilio"
                            class="button-toggle-closed-invoice"
                            aria-label="Exibir faturas em aberto/pagas"
                            (click)="isClosedInvoiceTwilio = !isClosedInvoiceTwilio; findNotificationStatement()">
                        <mat-icon svgIcon="eye"> </mat-icon>
                        {{ isClosedInvoiceTwilio ? 'Exibir faturas em aberto' : 'Exibir faturas pagas' }}
                    </button>
                </label>
                
                <div id="contentInformation"
                     fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px">
                    <gipi-month-year-picker label="Período"
                                            help="Considera do primeiro ao último dia do mês"
                                            id="panelTwilioPeriodicity"
                                            name="panelTwilioPeriodicity"
                                            [required]="true"
                                            [disabled]="loading"
                                            [calendarView]="'both'"
                                            [(ngModel)]="panelTwilioPeriodicity"
                                            (ngModelChange)="findNotificationStatement()"
                                            fxFlex>
                    </gipi-month-year-picker>

                    
                    <div class="input-info-invoice">
                        <label>
                            Quantidade
                            <mat-icon matTooltip="Quantidade de mensagens enviadas">
                                help
                            </mat-icon>
                        </label>
                        <div> {{ panelTwilioNotificationStatement?.quantity }} </div>
                    </div>

                    
                    <div class="input-info-invoice">
                        <label>
                            Valor total
                            <mat-icon matTooltip="Valor total = (Quantidade * Valor unitário)">
                                help
                            </mat-icon>
                        </label>
                        <div> {{ panelTwilioNotificationStatement?.price | currency:'BRL':'symbol':'1.2-2' }} </div>
                    </div>

                    
                    <div class="input-info-invoice">
                        <label>
                            Status
                            <mat-icon matTooltip="Indica se o período está em aberto ou se foi pago">
                                help
                            </mat-icon>
                        </label>
                        <div> {{ (panelTwilioStatusInvoice | async) }}</div>
                    </div>
                    
                </div>
                
            </div>
            -->



            
            <div id="panelTwilioInfoSender"
                 class="content-information">
                <label for="contentInformation">
                    Informações do remetente
                </label>
                <div id="contentInformation"
                     fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px">
                    <gipi-input-phone label="Telefone"
                                      id="panelTwilioPhoneNumber"
                                      name="panelTwilioPhoneNumber"
                                      [placeholder]="'(00) 0 0000-0000'"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(ngModel)]="customerNotificationConfig.phoneNumber"
                                      fxFlex="24"
                                      fxFlex.lt-md="100">
                    </gipi-input-phone>
                    <itss-input label="E-mail"
                                id="panelTwilioEmail"
                                name="panelTwilioEmail"
                                [type]="'email'"
                                [placeholder]="'exemplo@email.com'"
                                [required]="true"
                                [disabled]="loading"
                                [lowerCase]="true"
                                [(ngModel)]="customerNotificationConfig.emailCustomer"
                                (focusout)="validateEmail(customerNotificationConfig.emailCustomer)"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                </div>
            </div>

            <div id="panelTwilioOptionsMessages">
                <div *ngFor="let option of panelTwilioOptions; let i = index;"
                     [attr.id]="option.id"
                     [class.selected]="option.selected"
                     class="panel-notification">
                    <div class="informations">
                        <strong> {{ option.title }} </strong>
                        <small> {{ option.description }} </small>
                    </div>

                    <gipi-slide-toggle [id]="'twilioOption' + i"
                                       [name]="'twilioOption' + i"
                                       [(ngModel)]="option.selected">
                    </gipi-slide-toggle>
                </div>
            </div>
        </div>
    </div> 

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnIntegrationsConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnIntegrationsReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
    </div>
</gipi-abstract-form>