<gipi-abstract-form id="dialogConfiguration"
                    name="dialogConfiguration"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogConfiguration">
        Configurações
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <gipi-tab-group [ngStyle]="{'opacity': isLoad ? '0.6' : '1'}">
            <gipi-tab label="Geral"
                      [disabled]="isLoad"
                      id="tabSystem">
                <ng-container *ngTemplateOutlet="systemConfigTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Contas a Receber"
                      [disabled]="isLoad"
                      id="tabBillReceivable">
                <ng-container *ngTemplateOutlet="billReceivableConfigTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Crédito do cliente"
                      [disabled]="isLoad"
                      id="tabClientCredit">
                <ng-container *ngTemplateOutlet="clientCreditConfigTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Vendas"
                      [disabled]="isLoad"
                      id="tabSale">
                <ng-container *ngTemplateOutlet="saleConfigTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="WhatsApp"
                      [disabled]="isLoad"
                      id="tabWhatsapp">
                <ng-container *ngTemplateOutlet="whatsappConfigTemplate"> </ng-container>
            </gipi-tab>
        </gipi-tab-group>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="isLoad"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #systemConfigTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row">
            <gipi-input-select-paged label="Categoria para recebimentos com cartão"
                                     id="billReceivablePostingCategoryForReceivementCardAdministrator"
                                     name="postingCategoryForReceivementCardAdministrator"
                                     [(model)]="configuration.postingCategoryForReceivementCardAdministrator"
                                     [nextBatchFn]="postingCategoryForReceivementCardAdministratorFindByValueFn"
                                     [propertyFn]="postingCategoryFn"
                                     [disabled]="isLoad || loading"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-checkbox color="primary"
                           label="Mostrar clientes desativados em pesquisas"
                           [(ngModel)]="configuration.showsDisabledCustomerInSearch"
                           id="billReceivableConfigShowsDisabledCustomerInSearch"
                           name="showsDisabledCustomerInSearch"
                           [disabled]="isLoad || loading"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
            <itss-checkbox label="Mostrar nome fantasia nas consultas"
                           id="billReceivableConfigShowBusinessNameInReceiptQuery"
                           name="showBusinessNameInReceiptQuery"
                           color="primary"
                           [disabled]="isLoad || loading"
                           [(ngModel)]="configuration.showBusinessNameInReceiptQuery"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #billReceivableConfigTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-input label="Dias de carência"
                        [(ngModel)]="configuration.graceDays"
                        id="billReceivableConfigGraceDays"
                        name="graceDays"
                        mask="0*"
                        maxlength="3"
                        [disabled]="isLoad || loading"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Dias p/ negativação"
                        [(ngModel)]="configuration.daysToNegative"
                        id="billReceivableConfigDaysToNegative"
                        name="daysToNegative"
                        mask="0*"
                        maxlength="3"
                        [disabled]="isLoad || loading"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input-currency label="Juros por atraso (%)"
                                 [(ngModel)]="configuration.interest"
                                 id="billReceivableConfigInterest"
                                 name="interest"
                                 [disabled]="isLoad || loading"
                                 [options]="{ min: 0, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Multa por atraso (%)"
                                 [(ngModel)]="configuration.fine"
                                 id="billReceivableConfigFine"
                                 name="fine"
                                 [disabled]="isLoad || loading"
                                 [options]="{ min: 0, prefix: '', suffix: ' %' }"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <gipi-input-select-paged label="Categoria acréscimo (Crédito)"
                                     id="billReceivablePostingCategoryForAdditionToCredit"
                                     name="postingCategoryForAdditionToCredit"
                                     [(model)]="configuration.postingCategoryForAdditionToCredit"
                                     [nextBatchFn]="postingCategoryForAdditionToCreditFindByValueFn"
                                     [propertyFn]="postingCategoryFn"
                                     [disabled]="isLoad || loading"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
            <gipi-input-select-paged label="Categoria acréscimo (Débito)"
                                     id="billReceivablePostingCategoryForAdditionToDebit"
                                     name="postingCategoryForAdditionToDebit"
                                     [(model)]="configuration.postingCategoryForAdditionToDebit"
                                     [nextBatchFn]="postingCategoryForAdditionToDebitFindByValueFn"
                                     [propertyFn]="postingCategoryFn"
                                     [disabled]="isLoad || loading"
                                     fxFlex
                                     fxFlex.lt-md="100">
            </gipi-input-select-paged>
        </div>
        <div fxLayout="row"
             style="margin-bottom: 25px !important;">
            <gipi-radio-group label="Desbloquear os clientes bloqueados"
                              [(ngModel)]="configuration.unlockBlockedClient"
                              id="billReceivableConfigUnlockBlockedClient"
                              name="unlockBlockedClient"
                              [enum]="unlockBlockedClientEnum"
                              [disabled]="isLoad || loading"
                              [fieldset]="true"
                              fxFlex
                              fxFlex.lt-md="100">
            </gipi-radio-group>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-checkbox label="Imprimir parcelas pendentes no recibo"
                           id="billReceivableConfigPrintPendingInstallmentsOnReceipt"
                           name="printPendingInstallmentsOnReceipt"
                           color="primary"
                           [disabled]="isLoad || loading"
                           [(ngModel)]="configuration.printPendingInstallmentsOnReceipt"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
            <itss-checkbox label="Baixa automática do crédito do cliente"
                           id="billReceivableConfigAutomateCreditRebate"
                           name="automateCreditRebate"
                           color="primary"
                           [disabled]="isLoad || loading"
                           [(ngModel)]="configuration.automateCreditRebate"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
            <itss-checkbox label="Bloquear clientes negativados"
                           id="billReceivableConfigBlockNegativeClients"
                           name="blockNegativeClients"
                           color="primary"
                           [disabled]="isLoad || loading"
                           [(ngModel)]="configuration.blockNegativeClients"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
            <itss-checkbox label="Imprimir recibo ao receber"
                           id="printReceiptAfterReceivement"
                           name="printReceiptReceivement"
                           color="primary"
                           [disabled]="isLoad || loading"
                           [(ngModel)]="configuration.printReceiptAfterReceivement"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-checkbox>
            
        </div>
    </div>
</ng-template>

<ng-template #clientCreditConfigTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <gipi-input-select-paged label="Categoria (Crédito)"
                                 id="clientCreditDefaultClientCreditCategoryToCredit"
                                 name="defaultClientCreditCategoryToCredit"
                                 [(model)]="configuration.defaultClientCreditCategoryToCredit"
                                 [nextBatchFn]="defaultClientCreditCategoryToCreditFindByValueFn"
                                 [propertyFn]="postingCategoryFn"
                                 [disabled]="isLoad || loading"
                                 fxFlex
                                 fxFlex.lt-md="100">
        </gipi-input-select-paged>
        <gipi-input-select-paged label="Categoria (Débito)"
                                 id="clientCreditDefaultClientCreditCategoryToDebit"
                                 name="defaultClientCreditCategoryToDebit"
                                 [(model)]="configuration.defaultClientCreditCategoryToDebit"
                                 [nextBatchFn]="defaultClientCreditCategoryToDebitFindByValueFn"
                                 [propertyFn]="postingCategoryFn"
                                 [disabled]="isLoad || loading"
                                 fxFlex
                                 fxFlex.lt-md="100">
        </gipi-input-select-paged>
    </div>
</ng-template>

<ng-template #saleConfigTemplate>
    <div fxLayout="row">
        <itss-input label="Cliente padrão para vendas"
                    id="saleConfigDefaultClientForSales"
                    name="defaultClientForSales"
                    [value]="configuration?.defaultClientForSales?.person?.name || ''"
                    [disabled]="isLoad || loading"
                    (keydown.enter)="selectClient()"
                    fxFlex
                    fxFlex.lt-md="100">
            <ng-template #suffix>
                <div fxLayout="row"
                     fxLayoutGap="5px">
                    <gipi-button tooltip="Buscar cliente"
                                 gipi-icon
                                 id="btnSearchClient"
                                 svgIcon="search"
                                 [forInput]="true"
                                 (click)="selectClient()">
                    </gipi-button>
                </div>
            </ng-template>
        </itss-input>
    </div>
</ng-template>

<ng-template #whatsappConfigTemplate>
    <div fxLayout="row"
         fxLayoutGap="16px"
         style="max-height: 40rem;"
         class="tab-whatsapp">
        <div class="messages">
            <header class="header">
                <div class="information">
                    <strong>Mensagens</strong>
                    <small>Clique na mensagem para editar ou visualizar</small>
                </div>
                <button matTooltip="Voltar todas as mensagens para o padrão"
                        class="btn-toggle"
                        type="button"
                        [disabled]="isLoad || loading"
                        (click)="restoreDefaultMessage(true)">
                    <mat-icon> replay</mat-icon>
                </button>
            </header>
            <ul class="list">
                <ng-container *ngFor="let option of wppOptionMessageList; let i = index;">
                    <li class="option"
                        [class.selected]="option.selected"
                        (click)="selectWppOptionMessage(option)">
                        <div class="description">
                            <strong> {{ option.title }} </strong>
                            <small> {{ option.description }} </small>
                        </div>
                        <gipi-slide-toggle [id]="'wppOptionMessage' + i"
                                           [name]="'wppOptionWessage' + i"
                                           [(ngModel)]="option.enabled"
                                           (click)="$event.stopPropagation()">
                        </gipi-slide-toggle>
                    </li>
                </ng-container>
            </ul>
        </div>

        <div class="custom-messages"
             *ngIf="hasWppOptionMessageSelected && wppOptionMessageSelected">
            <header class="header">
                <div class="information">
                    <strong>Personalizar mensagem</strong>
                    <small>Ajuste a mensagem para atender às suas nescessidades</small>
                </div>
            </header>
            <div fxLayout="column"
                 fxLayoutGap="16px"
                 style="padding: 1rem; flex: 1;">
                <div class="text-editor">
                    <div class="toolbar">
                        <span class="btn-formats">
                            <button matTooltip="Negrito"
                                    class="btn-toggle"
                                    type="button"
                                    [disabled]="isLoad || loading"
                                    (click)="applyFormat('*')">
                                <mat-icon> format_bold </mat-icon>
                            </button>
                            <button matTooltip="Itálico"
                                    class="btn-toggle"
                                    type="button"
                                    [disabled]="isLoad || loading"
                                    (click)="applyFormat('_')">
                                <mat-icon> format_italic </mat-icon>
                            </button>
                            <button matTooltip="Tachado"
                                    class="btn-toggle"
                                    type="button"
                                    [disabled]="isLoad || loading"
                                    (click)="applyFormat('~')">
                                <mat-icon> strikethrough_s</mat-icon>
                            </button>
                            <button matTooltip="Código"
                                    class="btn-toggle"
                                    type="button"
                                    [disabled]="isLoad || loading"
                                    (click)="applyFormat('```')">
                                <mat-icon> code </mat-icon>
                            </button>
                            <button matTooltip="Selecione o texto para remover formatação"
                                    class="btn-toggle"
                                    type="button"
                                    [disabled]="isLoad || loading"
                                    (click)="clearFormatting()">
                                <mat-icon> format_clear</mat-icon>
                            </button>
                        </span>

                        <button matTooltip="Voltar mensagem para o padrão"
                                class="btn-toggle"
                                type="button"
                                [disabled]="isLoad || loading"
                                (click)="restoreDefaultMessage(false)">
                            <mat-icon> replay</mat-icon>
                        </button>
                    </div>
                    <textarea class="editor"
                              id="textEditor"
                              name="textEditor"
                              #wppTextEditor
                              minlength="3"
                              maxlength="1000"
                              [(ngModel)]="wppOptionMessageSelected.message">
                    </textarea>
                    <div class="footer">
                        <div class="separator">
                            <strong>Parâmetros</strong>
                            <span class="line"></span>
                        </div>
                        <span class="options-params"
                              *ngIf="wppOptionMessageSelected?.params?.length">
                            <button matTooltip="Exibirá o nome fantasia da empresa"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${company}')"
                                    (click)="insertParam('${company}')">
                                Empresa
                            </button>
                            <button matTooltip="Exibirá o nome da pessoa"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${name}')"
                                    (click)="insertParam('${name}')">
                                Nome
                            </button>
                            <button matTooltip="Exibirá o número do documento"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${documentNumber}')"
                                    (click)="insertParam('${documentNumber}')">
                                N° Documento
                            </button>
                            <button matTooltip="Exibirá o valor"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${amount}')"
                                    (click)="insertParam('${amount}')">
                                Valor
                            </button>
                            <button matTooltip="Exibirá a chave de pagamento do boleto"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${paymentKey}')"
                                    (click)="insertParam('${paymentKey}')">
                                Boleto bancário
                            </button>
                            <button matTooltip="Exibirá a data de emissão"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${issuanceDate}')"
                                    (click)="insertParam('${issuanceDate}')">
                                Dt. Emissão
                            </button>
                            <button matTooltip="Exibirá a data de vencimento"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${dueDate}')"
                                    (click)="insertParam('${dueDate}')">
                                Dt. Vencimento
                            </button>
                            <button matTooltip="Exibirá a descrição"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${description}')"
                                    (click)="insertParam('${description}')">
                                Descrição
                            </button>
                            <button matTooltip="Exibirá a data de inicio"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${startDate}')"
                                    (click)="insertParam('${startDate}')">
                                Dt. inicio
                            </button>
                            <button matTooltip="Exibirá a data de término"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${endDate}')"
                                    (click)="insertParam('${endDate}')">
                                Dt. término
                            </button>
                            <button matTooltip="Exibirá o telefone para contato"
                                    type="button"
                                    class="btn-param"
                                    *ngIf="isValidParamToShow('${contact}')"
                                    (click)="insertParam('${contact}')">
                                Contato
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div fxLayout="row"
                 fxLayoutAlign="end end"
                 fxLayoutGap="16px"
                 style="padding: 0 1rem 1rem 1rem;">
                <button class="btn-cancel"
                        id="btnCancel"
                        [disabled]="isLoad || loading"
                        (click)="cancelCustomMessage()">
                    Cancelar edição
                </button>
            </div>
        </div>

        <div class="empty-selection"
             *ngIf="!hasWppOptionMessageSelected">
            <mat-icon svgIcon="wpp_select_messages"> </mat-icon>
            <div class="description">
                <strong>Selecione um tipo de mensagem</strong>
                <p>Ao selecionar, ela aparecerá aqui para personalização</p>
            </div>
        </div>
    </div>
</ng-template>
