import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';

import { FinancialBilletFinancialService } from '@gipi-billet/bank-slip/services/billet-financial.service';
import { FinancialBankAccountSelectDTO } from '@gipi-financial/bank-account/models/dto/bank-account-select.dto';
import { FinancialInstallmentEditTypeEnum } from '@gipi-financial/bill/enums/installment-edit-type.enum';
import { FinancialPeriodicity, FinancialPeriodicityEnum } from '@gipi-financial/bill/enums/periodicity.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialBillService } from '@gipi-financial/bill/services/bill.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { BankCodes, BankCodeUtil } from '@gipi-shared/utils/bank-codes-billet.util';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, ConfirmationService, DateUtil, GIPIUuid, INJECTOR, NumberUtil, ObjectUtil, StringUtil, TableColumnBuilder, TableColumnDTO, UUIDUtil } from '@gipisistemas/ng-core';

export interface InstallmentDialogData {
    billInstallment: FinancialBillInstallment;
    billInstallmentList: FinancialBillInstallment[];
    isRenegotiate: boolean;
}

@Component({
    templateUrl: './installment-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class InstallmentDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('documentNumberTemplate', { static: true }) documentNumberTemplate: TemplateRef<any>;

    @ViewChild('dueDateTemplate', { static: true }) dueDateTemplate: TemplateRef<any>;

    @ViewChild('amountTemplate', { static: true }) amountTemplate: TemplateRef<any>;

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    installment: FinancialBillInstallment;

    installmentList: FinancialBillInstallment[] = [];

    installmentFilteredList: FinancialBillInstallment[] = [];

    billIntallmentSelected: FinancialBillInstallment[] = [];

    periodicity: FinancialPeriodicity = 'MONTHLY';

    periodicityDay: number;

    periodicityEnum: typeof FinancialPeriodicityEnum = FinancialPeriodicityEnum;

    portionNumber: any;

    portionNumberList: any[] = [];

    portionStart: { id: number } = { id: 1 };

    portionStartList: { id: number, description: string }[] = [];

    columns: TableColumnDTO[];

    editing: boolean = false;

    editTypeEnum: typeof FinancialInstallmentEditTypeEnum = FinancialInstallmentEditTypeEnum;

    editType: FinancialInstallmentEditTypeEnum | string = 'ALL';

    amount: number;

    originalAmount: number;

    isLoad: boolean = false;

    date: Date = new Date();

    compareDate: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0);

    isGeneratingBillet: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: InstallmentDialogData = { billInstallment: null, billInstallmentList: [], isRenegotiate: false },
        public dialogRef: MatDialogRef<InstallmentDialogComponent>,
        private _billInstallmentService: FinancialBillInstallmentService,
        private _billService: FinancialBillService,
        private _confirmationService: ConfirmationService,
        private _billetFinancialService: FinancialBilletFinancialService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.isLoad = true;
        this.installment = this.data.billInstallment;
        this.billIntallmentSelected = this.data.billInstallmentList;
        await this.editingLoad();
        this.sortBillInstallment();
        this.createTableColumns();
        setTimeout(() => this.generatePortionNumberList());
        this.isLoad = false;
    }

    async editingLoad(): Promise<void> {
        try {
            this.isLoad = true;
            this.loading = true;
            this.editing = !ObjectUtil.isNewModel(this.installment);

            if (this.editing) {
                await this._billInstallmentService.findByBillId(this.installment.bill.id).toPromise().then(installmentList => {
                    installmentList.forEach(i => {
                        // A data deve ser formatada devido o time zone.
                        i.dueDate = new Date(moment(i.dueDate).format('yyyy/MM/DD'));

                        const duoDate: Date = new Date(i.dueDate);
                        if (DateUtil.isLessThan(duoDate, this.compareDate) && i.status === 'RECEIVABLE') {
                            i.status = 'RECEIVABLE_OVERDUE';
                        } else if (DateUtil.isLessThan(duoDate, this.compareDate) && i.status === 'PAYABLE') {
                            i.status = 'PAYABLE_OVERDUE';
                        }
                    });
                    this.originalAmount = installmentList.reduce((sum, el) => sum += el.amount, 0);
                    this.installmentList = installmentList;
                    this.portionNumber = installmentList.length;
                    this.filterInstallmentList(this.editType);
                }, error => {
                    this.isLoad = false;
                    this.loading = false;
                    this.handleError(error);
                });
            }
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        } finally {
            this.isLoad = false;
            this.loading = false;
        }
    }

    private sortBillInstallment(): void {
        this.installmentFilteredList.sort((a, b) => {
            const lFieldA: string | number = a.portion;
            const lFieldB: string | number = b.portion;
            return this.compareSort(lFieldA, lFieldB, true);
        });
    }

    private compareSort(a: number | string, b: number | string, isAsk: boolean): number {
        return (a < b ? -1 : 1) * (isAsk ? 1 : -1);
    }

    protected createTableColumns(): void {
        this.columns = [
            TableColumnBuilder.instance()
                .property('portion')
                .name('Nr.')
                .value((obj: FinancialBillInstallment) => ('00' + obj.portion).slice(-2))
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .value((obj: FinancialBillInstallment) => obj.description)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('N° documento')
                .template(this.documentNumberTemplate)
                .width(15)
                .visible(UUIDUtil.isValid(this.installment.bill.id) || this.installment.bill.type === 'PAYABLE')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .template(this.dueDateTemplate)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .template(this.amountTemplate)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(7)
                .visible(this.editing)
                .template(this.statusTemplate)
                .build(),
        ];
    }

    setColorStatus(entity: FinancialBillInstallment): string {
        switch (String(entity.status)) {
            case 'RECEIVABLE': return '#b4b4b4';
            case 'RECEIVED': return '#56b910';
            case 'RECEIVED_PARTIAL': return '#62757f';
            case 'GROUPED': return '#8256D0';
            case 'RENEGOTIATED': return '#2bd7d9';
            case 'RECEIVABLE_OVERDUE': return '#f5db00';
            case 'PAYABLE_OVERDUE': return '#f5db00';
            case 'PAYABLE': return '#b4b4b4';
            case 'PAID': return '#56b910';
            default: return '#b4b4b4';
        }
    }

    setHintStatus(entity: FinancialBillInstallment): string {
        switch (String(entity.status)) {
            case 'RECEIVABLE': return 'A receber';
            case 'RECEIVED': return 'Recebida';
            case 'RECEIVED_PARTIAL': return 'Recebida parcialmente';
            case 'GROUPED': return 'Agrupada';
            case 'RENEGOTIATED': return 'Renegociada';
            case 'RECEIVABLE_OVERDUE': return 'A receber atrasada';
            case 'PAYABLE_OVERDUE': return 'A pagar atrasada';
            case 'PAYABLE': return 'A pagar';
            case 'PAID': return 'Paga';
            default: return 'Não identificado';
        }
    }

    generatePortionNumberList(): void {
        const portionNumberList = [];
        Array.from(Array(81).keys()).filter(value => value > 1).forEach(value =>
            portionNumberList.push({
                id: value,
                description: ('00' + value).slice(-2)
            }));
        this.portionNumberList = portionNumberList;
    }

    selectionChangePortionNumber(portionNumber: any): void {
        this.portionNumber = portionNumber;
        const portionStartList = [];
        Array.from(Array(81).keys()).filter(value => value && value <= portionNumber.id).forEach(value =>
            portionStartList.push({
                id: value,
                description: ('00' + value).slice(-2)
            }));
        this.portionStartList = portionStartList;
        this.generate();
    }

    async save(): Promise<void> {
        this.isLoad = true;
        this.loading = true;
        try {
            const isValid: boolean = this.editing ? this.isValidEditing() : this.isValid();
            if (!isValid) {
                this.loading = false;
                this.isLoad = false;
                throw new Error('Campos obrigatórios (*) não informados');
            }

            if (!this.editing && ArrayUtil.isEmpty(this.installmentList)) {
                await this.generate();
            }

            const bankAccount: FinancialBankAccountSelectDTO = FinancialBankAccountSelectDTO.transformToDTO(this.data.billInstallment.bankAccount);
            if ((this.data.billInstallment.bill.type === 'RECEIVABLE') && (this.data.billInstallment.chargeType.type === 'BANK_SLIP')) {
                if (bankAccount.type !== 'CHECKING_ACCOUNT') {
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
                    this.isLoad = false;
                    this.loading = false;
                    return;
                }

                const allowedBankAccount: boolean = BankCodeUtil.isValidBankCode((bankAccount.bankCode as BankCodes));
                if (!allowedBankAccount) {
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja válida');
                    this.isLoad = false;
                    this.loading = false;
                    return;
                }
            }

            const lBill: FinancialBill = await this.billInstallListToBill(this.installmentList);

            if (this.data.isRenegotiate) {
                await this._billService.generateRenegotiationList(lBill, this.billIntallmentSelected, this.installment.userId);
            }

            await this._billService.save(lBill).toPromise().then(bill => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                if ((this.data.billInstallment.bill.type === 'RECEIVABLE') && (this.data.billInstallment.chargeType.type === 'BANK_SLIP')) {
                    this.confirmGenerateBankSlip(bill);
                } else {
                    this.close(true);
                }
            }, error => {
                this.loading = false;
                this.isLoad = false;
                throw new Error(error);
            });

        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }

    async billInstallListToBill(billInstallmentList: FinancialBillInstallment[]): Promise<FinancialBill> {
        const billInstallmentListClone: FinancialBillInstallment[] = [];
        billInstallmentList.forEach((bi => {
            const billInstalment: FinancialBillInstallment = ObjectUtil.clone(bi);
            billInstalment.bill = null;
            billInstallmentListClone.push(billInstalment);
        }));
        const bill: FinancialBill = ObjectUtil.clone(billInstallmentList[0].bill);
        bill.billInstallmentList = billInstallmentListClone;
        return Promise.resolve(bill);
    }

    close(navigate: boolean): void {
        this.dialogRef.close(navigate);
    }

    selectionChangePortionStart(portionStart: any): void {
        this.portionStart = portionStart;
        this.generate();
    }

    isValidEditing(): boolean {
        const installmentList: FinancialBillInstallment[] = this.installmentFilteredList.filter(i => i.status === 'RECEIVABLE' || i.status === 'RECEIVABLE_OVERDUE' || i.status === 'PAYABLE' || i.status === 'PAYABLE_OVERDUE');
        return (installmentList.every(i => DateUtil.isValid(new Date(moment(i.dueDate).format('yyyy/MM/DD'))))
            && installmentList.every(i => NumberUtil.isPositive(i.amount)));
    }

    isValid(): boolean {
        return NumberUtil.isPositive(this.installment.amount)
            && !ObjectUtil.isNull(this.installment.dueDate)
            && !ObjectUtil.isNull(this.periodicity)
            && (this.periodicity !== 'FREE' || !ObjectUtil.isNull(this.periodicityDay))
            && !ObjectUtil.isNull(this.portionNumber)
            && !ObjectUtil.isNull(this.portionStart)
            && this.installmentList.every(i => NumberUtil.isPositive(i.amount));
    }

    async generate(): Promise<void> {
        this.loading = true;
        this.isLoad = true;
        try {
            if (!this.isValid()) {
                this.loading = false;
                this.isLoad = false;
                return;
            }

            if ((this.installment.amount / this.portionNumber.id) < 1) {
                this.loading = false;
                this.isLoad = false;
                this.installmentList = [];
                throw new Error('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
            }

            const installmentList: FinancialBillInstallment[] = [];
            const description: string = StringUtil.isEmpty(this.installment.description) ? '' : this.installment.description;

            for (let i: number = 0; i <= this.portionNumber.id - 1; i++) {
                const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
                installment.bill.periodicity = this.periodicity;
                installment.portion = i + this.portionStart.id;
                installment.description = `${description} ${installment.portion}/${this.portionNumber.id}`;
                installment.dueDate = this.getDuoDate(i);

                const amountPortion: number = (this.installment.amount / this.portionNumber.id);
                installment.amount = Number(amountPortion.toFixed(2));
                installment.originalAmount = Number(amountPortion.toFixed(2));

                installment.ourNumber = null;

                installmentList.push(installment);
            }

            const amountTotalPortion: number = Number(installmentList.reduce((sum, installment) => sum += installment.amount, 0));
            const amountDifference: number = Number(amountTotalPortion.toFixed(2)) - this.installment.amount;
            if (amountDifference > 0) {
                installmentList[0].amount -= amountDifference;
            } else if (amountDifference < 0) {
                const difference: number = (amountDifference * -1);
                installmentList[0].amount += difference;
            }

            this.installmentList = installmentList;
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        } finally {
            this.loading = false;
            this.isLoad = false;
        }

        return Promise.resolve();
    }

    getDuoDate(portionNumber: number): Date {
        const date: Date = new Date(moment(this.installment.dueDate).format('yyyy/MM/DD'));
        switch (this.periodicity.toString()) {
            case 'WEEKLY':
                date.setDate(date.getDate() + portionNumber * 7);
                break;
            case 'MONTHLY':
                date.setMonth(date.getMonth() + portionNumber);
                break;
            case 'BIMONTHLY':
                date.setMonth(date.getMonth() + portionNumber * 2);
                break;
            case 'QUARTERLY':
                date.setMonth(date.getMonth() + portionNumber * 3);
                break;
            case 'SEMESTER':
                date.setMonth(date.getMonth() + portionNumber * 6);
                break;
            case 'YEARLY':
                date.setFullYear(date.getFullYear() + portionNumber);
                break;
            case 'FREE':
                date.setDate(date.getDate() + portionNumber * this.periodicityDay);
                break;
        }
        return date;
    }

    async delete(isDeleteAll: boolean, installment?: FinancialBillInstallment): Promise<void> {
        this.isLoad = true;
        this.loading = true;
        try {
            if (this.installment.billRenegotiated) {
                const listInstallment: FinancialBillInstallment[] = this.installmentFilteredList.filter(i => i.status !== 'RECEIVABLE' && i.status !== 'RECEIVABLE_OVERDUE');
                if (listInstallment.length <= 0) {
                    await this.deleteRenegotiated(this.installment);
                } else {
                    const numberDcoumentRenegotietedList: string[] = listInstallment.map(i => i.documentNumber);
                    this.addErrorMessage(`Não é possível realizar a exclusão pois a(s) parcela(s) ${numberDcoumentRenegotietedList.toString()}, possuem movimentação`);
                }
            } else {
                await this.deleteInstallment(isDeleteAll, installment);
            }
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        } finally {
            this.isLoad = false;
            this.loading = false;
        }
    }

    async deleteInstallment(isDeleteAll: boolean, installment?: FinancialBillInstallment): Promise<void> {
        this._confirmationService.confirm({
            title: 'Confirmação',
            message: INJECTOR.get(APP_MESSAGES).DELETE_CONFIRMATION,
            accept: async () => {
                if (!isDeleteAll) {
                    if (this.installmentList.length === 1) {
                        this._billService.delete(this.installment.bill.id).toPromise().then(() => {
                            this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                            this.close(true);
                        });
                    } else {
                        const installmentList: FinancialBillInstallment[] = [];
                        installmentList.push(...this.installmentFilteredList);
                        installmentList.splice(installmentList.indexOf(installment), 1);
                        this.installmentFilteredList = installmentList;
                        this.installmentList = this.installmentFilteredList;
                    }
                } else {
                    const installmentDeleted: GIPIUuid[] = this.installmentFilteredList.filter(i => i.status === 'PAYABLE' || i.status === 'PAYABLE_OVERDUE' || i.status === 'RECEIVABLE' || i.status === 'RECEIVABLE_OVERDUE').map(i => i.id);
                    await this._billInstallmentService.deleteAll(installmentDeleted, 'v2').toPromise().then(() => {
                        const installmentList: FinancialBillInstallment[] = [];
                        installmentList.push(...this.installmentFilteredList.filter(t => !installmentDeleted.some(id => id === t.id)));
                        this.installmentFilteredList = installmentList;

                        this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                        const countInstallment: number = this.installmentFilteredList.filter(i => i.status === 'PAYABLE' || i.status === 'PAYABLE_OVERDUE' || i.status === 'RECEIVABLE' || i.status === 'RECEIVABLE_OVERDUE').length;
                        if (ArrayUtil.isEmpty(this.installmentFilteredList) || countInstallment <= 0) {
                            this.close(true);
                        }
                    });
                }
            }
        });
    }

    async deleteRenegotiated(installment: FinancialBillInstallment): Promise<void> {
        await this._billInstallmentService.findByBillId(installment.bill.id).toPromise().then((installmentList) => {
            const numberDcoumentRenegotietedList: string[] = installmentList.map(i => i.documentNumber);
            const messageInitial = 'A parcela foi gerada através de uma renegociação.';
            const messageFinal: string = (numberDcoumentRenegotietedList.length > 1
                ? `${messageInitial} Portanto sua exclusão irá excluir todas as parcelas vinculadas na renegociação. Número dos documentos que serão excluídos: ${numberDcoumentRenegotietedList.toString()}, deseja continuar?`
                : `${messageInitial} Portanto sua exclusão irá estornar a renegociação, deseja continuar?`);
            this.deleteConfirmationRenegotieted(installment, messageFinal, installmentList);
        });
    }

    deleteConfirmationRenegotieted(entity: FinancialBillInstallment, messages: string, billInstallmentList: FinancialBillInstallment[]): void {
        try {
            this._confirmationService.confirm({
                title: 'Confirmação',
                message: messages,
                accept: async () => {
                    let renegotieted: boolean = false;
                    billInstallmentList.forEach(installment => {
                        if (installment.status === 'RENEGOTIATED') {
                            renegotieted = true;
                        }
                    });
                    if (renegotieted) {
                        this.loading = false;
                        this.handleError(`Não é possível excluir parcelas renegociadas que já tiveram novas renegociações. É necessário antes excluir as parcelas geradas por essa renegociação.`);
                    } else {
                        await this._billInstallmentService.delete(entity.id).toPromise().then(() => {
                            this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                            this.loading = false;
                            this.close(true);
                        }, error => {
                            this.isLoad = false;
                            this.loading = false;
                            this.handleError(error);
                        });
                    }
                }
            });
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    update(): void {
        this.isLoad = true;
        this.loading = true;
        try {
            if (ObjectUtil.isNull(this.editType) || !NumberUtil.isPositive(this.amount)) {
                this.isLoad = false;
                this.loading = false;
                throw new Error('Campos obrigatórios (*) não informados');
            }
            this.installmentFilteredList.filter(i => i.status !== 'PAID' && i.status !== 'RECEIVED' && i.status !== 'GROUPED' && i.status !== 'RENEGOTIATED' && i.status !== 'RECEIVED_PARTIAL')
                .forEach(i => { i.amount = this.amount; i.originalAmount = this.amount; });
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        } finally {
            this.isLoad = false;
            this.loading = false;
        }
    }

    filterInstallmentList(editType: FinancialInstallmentEditTypeEnum | string): void {
        this.loading = true;
        this.isLoad = true;
        try {
            switch (editType.toString()) {
                case 'ALL': {
                    this.installmentFilteredList = this.installmentList;
                    break;
                }
                case 'OPENED': {
                    this.installmentFilteredList = this.installmentList.filter(value => value.status === 'RECEIVABLE' || value.status === 'RECEIVABLE_OVERDUE' || value.status === 'PAYABLE' || value.status === 'PAYABLE_OVERDUE');
                    break;
                }
                case 'FUTURE': {
                    this.installmentFilteredList = this.installmentList.filter(value => new Date(moment(value.dueDate).format('yyyy/MM/DD')) > new Date());
                    break;
                }
                case 'OVERDUE': {
                    this.installmentFilteredList = this.installmentList.filter(value => value.status === 'RECEIVABLE_OVERDUE' || value.status === 'PAYABLE_OVERDUE');
                    break;
                }
                case 'PAID': {
                    this.installmentFilteredList = this.installmentList.filter(value => value.status === 'PAID' || value.status === 'RECEIVED');
                    break;
                }
                case 'PAYABLE': {
                    this.installmentFilteredList = this.installmentList.filter(value => value.status === 'PAYABLE' || value.status === 'RECEIVABLE');
                    break;
                }
            }
            this.sortBillInstallment();
        } catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        } finally {
            this.loading = false;
            this.isLoad = false;
        }
    }

    recalcAmounts(entity: FinancialBillInstallment): void {
        if (!this.editing) {
            const amountEditing: number = (this.editing ? this.originalAmount : this.installment.amount);
            const installmentFiltered: number = this.installmentList.filter(i => (i.status !== 'PAYABLE') && (i.status !== 'RECEIVABLE')).reduce((sum, i) => sum += i.amount, 0);
            const updatedList: FinancialBillInstallment[] = this.installmentList.filter(i => (i.portion > entity.portion) && (i.status === 'PAYABLE' || i.status === 'RECEIVABLE'));
            const amountOtherPortions: number = this.installmentList.filter(i => (i.portion !== entity.portion) && (i.status === 'PAYABLE' || i.status === 'RECEIVABLE')).reduce((sum, i) => sum += i.amount, 0);
            let remainingAmount: number = (amountEditing - amountOtherPortions - installmentFiltered);

            this.installmentList.forEach(installment => {
                if ((installment.status === 'PAYABLE' || installment.status === 'RECEIVABLE')) {
                    if (entity.amount === amountEditing) {
                        this.installmentList = [];
                        this.generate();
                        this.handleError('O valor total da conta não pode ser informado apenas em uma parcela');
                        return;
                    }
                    // Se ta zero é porque essa é a ultima parcela.
                    if (updatedList.length === 0) {
                        remainingAmount = (remainingAmount - entity.amount) / 1;
                        this.installmentList[0].amount += Number(remainingAmount.toFixed(2));
                        return;
                    } else {
                        if (installment.portion === entity.portion) {
                            remainingAmount = (remainingAmount - entity.amount) / updatedList.length;
                        }
                        if (installment.portion > entity.portion) {
                            installment.amount += Number(remainingAmount.toFixed(2));
                        }
                    }
                }
            });

            const amountTotalPortion: number = Number(this.installmentList.reduce((sum, installment) => sum += installment.amount, 0));
            const amountDifference: number = Number(amountTotalPortion.toFixed(2)) - amountEditing;
            if (amountDifference !== 0) {
                if (updatedList.length === 0) {
                    if (amountDifference > 0) {
                        this.installmentList[0].amount -= amountDifference;
                    } else if (amountDifference < 0) {
                        const difference = (amountDifference * -1);
                        this.installmentList[0].amount += difference;
                    }
                } else {
                    if (amountDifference > 0) {
                        this.installmentList[entity.portion + 1].amount -= amountDifference;
                    } else if (amountDifference < 0) {
                        const difference = (amountDifference * -1);
                        this.installmentList[entity.portion + 1].amount += difference;
                    }
                }
            }
        }
        entity.originalAmount = entity.amount;
    }

    async confirmGenerateBankSlip(bill: FinancialBill): Promise<void> {
        const isConfirmed: boolean = await this._confirmationService.confirm({
            title: 'Confirmação',
            message: 'Deseja imprimir o boleto?',
        });

        await this.generateBankSlip(bill.billInstallmentList, isConfirmed);
    }

    async generateBankSlip(installmentList: FinancialBillInstallment[], generatePdf: boolean): Promise<void> {
        try {
            this.loading = true;
            this.isGeneratingBillet = true;

            await this._billetFinancialService.generateBilletAll(installmentList, generatePdf).toPromise().then(resp => {
                if (generatePdf) {
                    const file: Blob = new Blob([resp.body], { type: 'application/pdf' });
                    const fileURL: string = URL.createObjectURL(file);
                    window.open(fileURL);
                }

                this.isGeneratingBillet = false;
                this.close(true);
            }, error => {
                this.isGeneratingBillet = false;
                this.isLoad = false;
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.isGeneratingBillet = false;
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

}
