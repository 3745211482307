import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;
import { FinancialBilletFinancialService } from '@gipi-billet/bank-slip/services/billet-financial.service';
import { FinancialPeriodicityEnum } from '@gipi-financial/bill/enums/periodicity.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillService } from '@gipi-financial/bill/services/bill.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { BankCodeUtil } from '@gipi-shared/utils/bank-codes-billet.util';
import { AbstractComponent, APP_MESSAGES, ConfirmationService, DateUtil, INJECTOR, MonthYear, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialBankAccountSelectDTO } from '@gipi-financial/bank-account/models/dto/bank-account-select.dto';
export class RecurringReleaseDialogComponent extends AbstractComponent {
    constructor(data, dialogRef, _billService, _confirmationService, _billetFinancialService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.data = data;
        this.dialogRef = dialogRef;
        this._billService = _billService;
        this._confirmationService = _confirmationService;
        this._billetFinancialService = _billetFinancialService;
        this.installmentList = [];
        this.periodicity = 'MONTHLY';
        this.periodicityEnum = FinancialPeriodicityEnum;
        this.dayList = [];
        this.begin = null;
        this.end = null;
        this.initialDueDate = null;
        this.selectPeriod = false;
        this._generateDayList();
    }
    ngOnInit() {
        super.ngOnInit();
        this.installment = this.data;
        setTimeout(() => {
            this.selectionChangePeriodicity();
        });
    }
    _generateDayList() {
        this.dayList = Array.from({ length: 31 }, (_, index) => ({
            id: index + 1,
            description: ('00' + (index + 1)).slice(-2)
        }));
    }
    selectionChangePeriodicity() {
        const date = new Date(moment(this.installment.dueDate).format('yyyy/MM/DD'));
        this.begin = new MonthYear(date.getMonth(), date.getFullYear());
        this.initialDueDate = new Date(date);
        this.selectPeriod = false;
    }
    modelChangeSelectPeriod() {
        const date = new Date(moment(this.installment.dueDate).format('yyyy/MM/DD'));
        this.day = this.dayList.find(day => day.id === date.getDate());
        this.begin = new MonthYear(date.getMonth(), date.getFullYear());
        this.initialDueDate = new Date(date);
        this.numberInstallments = 1;
        this.periodicityDay = 1;
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                const bankAccount = FinancialBankAccountSelectDTO.transformToDTO(this.installment.bankAccount);
                if ((this.installment.bill.type === 'RECEIVABLE') && (this.installment.chargeType.type === 'BANK_SLIP')) {
                    if (bankAccount.type !== 'CHECKING_ACCOUNT') {
                        this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
                        this.loading = false;
                        return;
                    }
                    const allowedBankAccount = BankCodeUtil.isValidBankCode(bankAccount.bankCode);
                    if (!allowedBankAccount) {
                        this.loading = false;
                        this.loadingChange.emit(this.loading);
                        this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja válida');
                        return;
                    }
                }
                this._generateInstallments();
                const bill = this.billInstallListToBill(this.installmentList);
                yield this._billService.save(bill).toPromise().then(b => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    if ((this.installment.bill.type === 'RECEIVABLE') && (this.installment.chargeType.type === 'BANK_SLIP')) {
                        this.confirmGenerateBankSlip(b);
                    }
                    else {
                        this.close(true);
                    }
                }, error => {
                    this.loading = false;
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    billInstallListToBill(billInstallmentList) {
        const billInstallmentListClone = [];
        for (let i = 0; i < billInstallmentList.length; i++) {
            const billInstalment = ObjectUtil.clone(billInstallmentList[i]);
            billInstalment.bill = null;
            billInstallmentListClone.push(billInstalment);
        }
        const bill = ObjectUtil.clone(billInstallmentList[0].bill);
        bill.billInstallmentList = billInstallmentListClone;
        return bill;
    }
    close(navigate) {
        this.dialogRef.close(navigate);
    }
    _validate() {
        if (!NumberUtil.isPositive(this.installment.amount)) {
            throw new Error('Valor é obrigatório e não foi informado');
        }
        else if (ObjectUtil.isNull(this.periodicity)) {
            throw new Error('Periodicidade é obrigatório e não foi informado');
        }
        else if ((this.periodicity.toString() !== 'MONTHLY') && (ObjectUtil.isNull(this.numberInstallments) || (Number(this.numberInstallments) <= 0))) {
            throw new Error('Qtd. de parcelas é obrigatório e não foi informado');
        }
        else if ((this.periodicity.toString() !== 'MONTHLY') && DateUtil.isLessThan(new Date(this.initialDueDate), new Date(this.installment.issuanceDate))) {
            throw new Error('Data de vencimento não pode ser menor que a data de emissão');
        }
        else if (this.periodicity.toString() === 'FREE' && (ObjectUtil.isNull(this.periodicityDay) || (Number(this.periodicityDay) <= 0))) {
            throw new Error('Qtd. de dias entre parcelas é obrigatório e não foi informado');
        }
        else if ((this.periodicity.toString() === 'MONTHLY') && (ObjectUtil.isNull(this.numberInstallments) || (Number(this.numberInstallments) <= 0))) {
            throw new Error('Qtd. de parcelas é obrigatório e não foi informado');
        }
        else if (this.periodicity.toString() === 'MONTHLY' && this.selectPeriod) {
            const issuanceDate = new Date(moment(this.installment.issuanceDate).format('yyyy/MM/DD'));
            const dateBegin = moment().day(this.day.id).month(this.begin.month).year(this.begin.year).toDate();
            const dateEnd = moment().day(this.day.id).month(this.end.month).year(this.end.year).toDate();
            const lastDayOfMonth = DateUtil.getLastDayMonth(this.begin.month);
            if (ObjectUtil.isNull(this.day)) {
                throw new Error('Dia do vencimento é obrigatório e não foi informado');
            }
            else if (ObjectUtil.isNull(this.begin)) {
                throw new Error('Mês/Ano inicial é obrigatório e não foi informado');
            }
            else if (ObjectUtil.isNull(this.end)) {
                throw new Error('Mês/Ano final é obrigatório e não foi informado');
            }
            else if (DateUtil.isLessThanMonth(dateEnd, dateBegin)) {
                throw new Error('Mês/Ano final não pode ser menor que Mês/Ano inicial');
            }
            else if (DateUtil.isLessThan(new Date(dateBegin), new Date(issuanceDate))) {
                throw new Error('Mês/Ano inicial não pode ser menor que a Data emissão');
            }
            else if (this.day.id > lastDayOfMonth) {
                throw new Error(`Mês/Ano inicial possui ${lastDayOfMonth} dias. Portanto, o dia do vencimento é inválido`);
            }
        }
    }
    _generateInstallments() {
        try {
            this.loading = true;
            this._validate();
            const dateList = this._getDateList();
            const description = StringUtil.isEmpty(this.installment.description) ? '' : this.installment.description;
            const installmentList = dateList.map(date => {
                const installment = ObjectUtil.clone(this.installment);
                installment.id = null;
                installment.saleBillInstallmentId = null;
                installment.bill.id = null;
                installment.bill.saleBillId = null;
                installment.documentNumber = null;
                installment.bill.periodicity = this.periodicity;
                installment.portion = dateList.indexOf(date) + 1;
                installment.description = description;
                installment.dueDate = date;
                installment.ourNumber = null;
                return installment;
            });
            // for (let i = 0; i < dateList.length; i++) {
            //     const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
            //     installment.id = null;
            //     installment.bill.periodicity = this.periodicity;
            //     installment.portion = dateList.indexOf(dateList[i]) + 1;
            //     installment.description = description;
            //     installment.dueDate = dateList[i];
            //     installment.ourNumber = null;
            //     installmentList.push(installment);
            // }
            // dateList.forEach(date => {
            //     const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
            //     installment.id = null;
            //     installment.bill.periodicity = this.periodicity;
            //     installment.portion = dateList.indexOf(date) + 1;
            //     installment.description = description;
            //     installment.dueDate = date;
            //     installment.ourNumber = null;
            //     installmentList.push(installment);
            // });
            this.installmentList = installmentList;
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    _getDateList() {
        const dateList = [];
        const dateInitial = this.selectPeriod
            ? moment().day(this.day.id).month(this.begin.month).year(this.begin.year).toDate()
            : new Date(this.initialDueDate);
        const dateFinal = this.selectPeriod
            ? moment().day(this.day.id).month(this.end.month).year(this.end.year).toDate()
            : null;
        if (this.selectPeriod) {
            dateInitial.setDate(this.day.id);
            dateFinal.setDate(this.day.id);
            while (DateUtil.isLessThanOrEqual(dateInitial, dateFinal, 'date')) {
                dateList.push(new Date(dateInitial));
                this._setDatePeriodicity(dateInitial);
            }
        }
        else {
            for (let i = 0; i < Number(this.numberInstallments); i++) {
                dateList.push(new Date(dateInitial));
                this._setDatePeriodicity(dateInitial);
            }
        }
        return dateList;
    }
    _setDatePeriodicity(date) {
        switch (this.periodicity.toString()) {
            case 'WEEKLY':
                date.setDate(date.getDate() + 7);
                break;
            case 'MONTHLY':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'BIMONTHLY':
                date.setMonth(date.getMonth() + 2);
                break;
            case 'QUARTERLY':
                date.setMonth(date.getMonth() + 3);
                break;
            case 'SEMESTER':
                date.setMonth(date.getMonth() + 6);
                break;
            case 'YEARLY':
                date.setFullYear(date.getFullYear() + 1);
                break;
            case 'FREE':
                date.setDate(date.getDate() + Number(this.periodicityDay));
                break;
        }
    }
    // selectionChangeDay(day: any): void {
    //     this.day = day;
    //     this.begin.setDate(this.day.id);
    //     this.end.setDate(this.day.id);
    // }
    confirmGenerateBankSlip(bill) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isConfirmed = yield this._confirmationService.confirm({
                title: 'Confirmação',
                message: 'Deseja imprimir o boleto?',
            });
            yield this.generateBankSlip(bill.billInstallmentList, isConfirmed);
        });
    }
    generateBankSlip(installmentList, generatePdf) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                yield this._billetFinancialService.generateBilletAll(installmentList, generatePdf).toPromise().then(resp => {
                    if (generatePdf) {
                        const file = new Blob([resp.body], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                    this.close(true);
                }, error => {
                    this.loading = false;
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
}
