import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialBillTypeEnum } from '@gipi-financial/bill/enums/bill-type.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillInstallmentConsultDTO } from '@gipi-financial/bill/models/dto/bill-installment-consult.dto';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialCashierShiftService } from '@gipi-financial/cashier-shift/services/cashier-shift.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialCancelPayment } from '@gipi-financial/payment/models/cancel-payment.model';
import { FinancialCancelPaymentDTO } from '@gipi-financial/payment/models/dto/cancel-payment.dto';
import { FinancialPaymentByCheckDTO } from '@gipi-financial/payment/models/dto/payment-by-check.dto';
import { FinancialCancelPaymentService } from '@gipi-financial/payment/services/cancel-payment.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, AuthenticationService, DateUtil, InputComponent, ObjectUtil, StringUtil, TokenDTO } from '@gipisistemas/ng-core';

export interface CancelPaymentDialogData {
    billIntallment: FinancialBillInstallmentConsultDTO;
    cashierShift?: FinancialCashierShift;
}

@Component({
    templateUrl: './cancel-payment-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class CancelPaymentDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('firstInput', { static: true }) firstInput: InputComponent;

    private _billInstallment: FinancialBillInstallment;

    cancelPaymentList: FinancialCancelPayment[] = [];

    paymentByCheckDTO: FinancialPaymentByCheckDTO = new FinancialPaymentByCheckDTO();

    cancelDate: Date = new Date();

    description: string = '';

    cashierShift: FinancialCashierShift;

    isCashier: boolean = false;

    typeBill: FinancialBillTypeEnum | string;

    isLoad: boolean = false;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _cancelPaymentService: FinancialCancelPaymentService,
        private _authenticationService: AuthenticationService<OAuthUser>,
        private _cashierShiftService: FinancialCashierShiftService,
        private _billInstallmentService: FinancialBillInstallmentService,
        public dialogRef: MatDialogRef<CancelPaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CancelPaymentDialogData = { billIntallment: null, cashierShift: null },
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this.isLoad = true;
        this.loading = true;
        super.ngOnInit();

        this.typeBill = this.data.billIntallment.type;

        this.isCashier = this._userIsCashier();
        if (this.isCashier) {
            if (ObjectUtil.isNewModel(this.data.cashierShift) || ObjectUtil.isNull(this.data.cashierShift)) {
                await this.getCashierShiftOpenedByUser(true).then(shift => {
                    if (shift) {
                        this.cashierShift = shift;
                    } else {
                        this.close('REMARK_SELECTED');
                    }
                });
            } else {
                this.cashierShift = this.data.cashierShift;
            }
        } else {
            this.cashierShift = null;
        }

        this._initializeHistoric();

        this.loading = false;
        this.isLoad = false;
        setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
    }

    private async _initializeHistoric(): Promise<void> {
        try {
            const billInstallmentAux: FinancialBillInstallment = await this._billInstallmentService.getOne(this.data.billIntallment.id).toPromise().catch(error => {
                this.isLoad = false;
                this.handleError(error);
                return null;
            });

            if (ObjectUtil.isNull(billInstallmentAux)) {
                this.addErrorMessage(`Ocorreu um erro ao consultar as contas ao para cancelar o ${this.typeBill === 'PAYABLE' ? 'pagamento' : 'recebimento'}, tente novamente`);
                this.close('NONE');
                return;
            }

            this._billInstallment = ObjectUtil.clone(billInstallmentAux);

            const initText: string = `Cancelamento da(s) conta(s) a ${this.typeBill === 'PAYABLE' ? 'pagar do fornecedor' : 'receber do cliente'} ${this._getNamePerson(this.data.billIntallment)}:\n`;
            const historic: string = `${!StringUtil.isEmpty(this._billInstallment.documentNumber) ? this._billInstallment.documentNumber : ' ' + '  -  '}${this._billInstallment.description}`;
            setTimeout(() => this.description = `${initText}${historic}`);
        } catch (e) {
            this.isLoad = false;
            this.handleError(e);
        }
    }

    private _getNamePerson(installment: FinancialBillInstallmentConsultDTO): string {
        const configuration: FinancialConfiguration = JSON.parse(sessionStorage.getItem('configuration'));
        let namePerson: string = installment.name;
        if (!ObjectUtil.isNull(configuration)) {
            namePerson = configuration.showBusinessNameInReceiptQuery
                ? (!StringUtil.isEmpty(installment.fantasyName) ? installment.fantasyName : installment.name)
                : installment.name;
        }

        return (namePerson.length > 90) ? namePerson.slice(0, 90) + '...' : namePerson;
    }

    private _userIsCashier(): boolean {
        const token: TokenDTO<OAuthUser> = this._authenticationService.token;
        return (!ObjectUtil.isNull(token) && token.user.cashier);
    }

    private async getCashierShiftOpenedByUser(close: boolean): Promise<FinancialCashierShift> {
        return new Promise(async (resolve, reject) => {
            if (this.isCashier) {
                await this._cashierShiftService.getOpenedByCurrentUser().toPromise().then(cashierShift => {
                    if (ObjectUtil.isNewModel(cashierShift)) {
                        resolve(null);
                        if (close) {
                            this.close('REMARK_SELECTED');
                        }
                        this.handleError('Não é possivel realizar o cancelamento. Operador de caixa deve abrir um turno');
                    } else {
                        resolve(cashierShift);
                    }
                }, (error) => {
                    resolve(null);
                    if (error.toLowerCase().includes('a consulta não retornou resultado único')) {
                        this.handleError(`Erro ao buscar turnos. ${error}`);
                    } else {
                        this.handleError(error);
                    }
                });
            }
        });
    }

    async validateCashierOpened(): Promise<boolean> {
        if (this.isCashier) {
            const cashierShift: FinancialCashierShift = await this.getCashierShiftOpenedByUser(false).then(shift => shift);
            return Promise.resolve(!ObjectUtil.isNewModel(cashierShift));
        } else {
            return Promise.resolve(true);
        }
    }

    private _newCancelPayment(): FinancialCancelPaymentDTO {
        const cancelPaymentDTO: FinancialCancelPaymentDTO = new FinancialCancelPaymentDTO();
        cancelPaymentDTO.description = this.description;
        cancelPaymentDTO.cancelDate = this.cancelDate;
        cancelPaymentDTO.billInstallmentList = [this._billInstallment];
        return cancelPaymentDTO;
    }

    async confirm(): Promise<void> {
        try {
            this.isLoad = true;
            this.loading = true;
            const cashierShiftOpened: boolean = await this.validateCashierOpened();
            if (!cashierShiftOpened) {
                throw new Error('Não é possivel realizar o cancelamento. Operador de caixa deve abrir um turno');
            }
            if (!DateUtil.isValid(this.cancelDate)) {
                throw new Error('Campo data do cancelamento está inválida');
            }
            if (StringUtil.isEmpty(this.description)) {
                throw new Error('Campo observação é obrigatório e não foi informado');
            }

            const cancelPaymentDTO: FinancialCancelPaymentDTO = this._newCancelPayment();
            this._cancelPaymentService.cancel(cancelPaymentDTO, this.typeBill).toPromise().then(CancelPaymentList => {
                if (!ArrayUtil.isEmpty(CancelPaymentList)) {
                    this.close('RELOAD_TABLE');
                } else {
                    this.close('NONE');
                }
            }, error => {
                this.isLoad = false;
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close({operation});
    }

}
