import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialCashierShiftService } from '@gipi-financial/cashier-shift/services/cashier-shift.service';
import { FinancialCancelPaymentDTO } from '@gipi-financial/payment/models/dto/cancel-payment.dto';
import { FinancialPaymentByCheckDTO } from '@gipi-financial/payment/models/dto/payment-by-check.dto';
import { FinancialCancelPaymentService } from '@gipi-financial/payment/services/cancel-payment.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, AuthenticationService, DateUtil, InputComponent, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class CancelPaymentDialogComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _cancelPaymentService, _authenticationService, _cashierShiftService, _billInstallmentService, dialogRef, data = { billIntallment: null, cashierShift: null }) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._cancelPaymentService = _cancelPaymentService;
        this._authenticationService = _authenticationService;
        this._cashierShiftService = _cashierShiftService;
        this._billInstallmentService = _billInstallmentService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.cancelPaymentList = [];
        this.paymentByCheckDTO = new FinancialPaymentByCheckDTO();
        this.cancelDate = new Date();
        this.description = '';
        this.isCashier = false;
        this.isLoad = false;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoad = true;
            this.loading = true;
            _super.ngOnInit.call(this);
            this.typeBill = this.data.billIntallment.type;
            this.isCashier = this._userIsCashier();
            if (this.isCashier) {
                if (ObjectUtil.isNewModel(this.data.cashierShift) || ObjectUtil.isNull(this.data.cashierShift)) {
                    yield this.getCashierShiftOpenedByUser(true).then(shift => {
                        if (shift) {
                            this.cashierShift = shift;
                        }
                        else {
                            this.close('REMARK_SELECTED');
                        }
                    });
                }
                else {
                    this.cashierShift = this.data.cashierShift;
                }
            }
            else {
                this.cashierShift = null;
            }
            this._initializeHistoric();
            this.loading = false;
            this.isLoad = false;
            setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
        });
    }
    _initializeHistoric() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const billInstallmentAux = yield this._billInstallmentService.getOne(this.data.billIntallment.id).toPromise().catch(error => {
                    this.isLoad = false;
                    this.handleError(error);
                    return null;
                });
                if (ObjectUtil.isNull(billInstallmentAux)) {
                    this.addErrorMessage(`Ocorreu um erro ao consultar as contas ao para cancelar o ${this.typeBill === 'PAYABLE' ? 'pagamento' : 'recebimento'}, tente novamente`);
                    this.close('NONE');
                    return;
                }
                this._billInstallment = ObjectUtil.clone(billInstallmentAux);
                const initText = `Cancelamento da(s) conta(s) a ${this.typeBill === 'PAYABLE' ? 'pagar do fornecedor' : 'receber do cliente'} ${this._getNamePerson(this.data.billIntallment)}:\n`;
                const historic = `${!StringUtil.isEmpty(this._billInstallment.documentNumber) ? this._billInstallment.documentNumber : ' ' + '  -  '}${this._billInstallment.description}`;
                setTimeout(() => this.description = `${initText}${historic}`);
            }
            catch (e) {
                this.isLoad = false;
                this.handleError(e);
            }
        });
    }
    _getNamePerson(installment) {
        const configuration = JSON.parse(sessionStorage.getItem('configuration'));
        let namePerson = installment.name;
        if (!ObjectUtil.isNull(configuration)) {
            namePerson = configuration.showBusinessNameInReceiptQuery
                ? (!StringUtil.isEmpty(installment.fantasyName) ? installment.fantasyName : installment.name)
                : installment.name;
        }
        return (namePerson.length > 90) ? namePerson.slice(0, 90) + '...' : namePerson;
    }
    _userIsCashier() {
        const token = this._authenticationService.token;
        return (!ObjectUtil.isNull(token) && token.user.cashier);
    }
    getCashierShiftOpenedByUser(close) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.isCashier) {
                    yield this._cashierShiftService.getOpenedByCurrentUser().toPromise().then(cashierShift => {
                        if (ObjectUtil.isNewModel(cashierShift)) {
                            resolve(null);
                            if (close) {
                                this.close('REMARK_SELECTED');
                            }
                            this.handleError('Não é possivel realizar o cancelamento. Operador de caixa deve abrir um turno');
                        }
                        else {
                            resolve(cashierShift);
                        }
                    }, (error) => {
                        resolve(null);
                        if (error.toLowerCase().includes('a consulta não retornou resultado único')) {
                            this.handleError(`Erro ao buscar turnos. ${error}`);
                        }
                        else {
                            this.handleError(error);
                        }
                    });
                }
            }));
        });
    }
    validateCashierOpened() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isCashier) {
                const cashierShift = yield this.getCashierShiftOpenedByUser(false).then(shift => shift);
                return Promise.resolve(!ObjectUtil.isNewModel(cashierShift));
            }
            else {
                return Promise.resolve(true);
            }
        });
    }
    _newCancelPayment() {
        const cancelPaymentDTO = new FinancialCancelPaymentDTO();
        cancelPaymentDTO.description = this.description;
        cancelPaymentDTO.cancelDate = this.cancelDate;
        cancelPaymentDTO.billInstallmentList = [this._billInstallment];
        return cancelPaymentDTO;
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoad = true;
                this.loading = true;
                const cashierShiftOpened = yield this.validateCashierOpened();
                if (!cashierShiftOpened) {
                    throw new Error('Não é possivel realizar o cancelamento. Operador de caixa deve abrir um turno');
                }
                if (!DateUtil.isValid(this.cancelDate)) {
                    throw new Error('Campo data do cancelamento está inválida');
                }
                if (StringUtil.isEmpty(this.description)) {
                    throw new Error('Campo observação é obrigatório e não foi informado');
                }
                const cancelPaymentDTO = this._newCancelPayment();
                this._cancelPaymentService.cancel(cancelPaymentDTO, this.typeBill).toPromise().then(CancelPaymentList => {
                    if (!ArrayUtil.isEmpty(CancelPaymentList)) {
                        this.close('RELOAD_TABLE');
                    }
                    else {
                        this.close('NONE');
                    }
                }, error => {
                    this.isLoad = false;
                    this.loading = false;
                    throw new Error(error);
                });
            }
            catch (e) {
                this.isLoad = false;
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close({ operation });
    }
}
