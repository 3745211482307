import { Injectable } from '@angular/core';

import { SalePaths } from '@gipi-paths/sale.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { SaleTypeChargeType } from '../enums/charge-type.enum';
import { SaleChargeType } from '../models/charge-type.model';
import { SaleChargeTypeFilterDTO } from '../models/dto/charge-type-filter.dto';

@Injectable({ providedIn: 'root' })
export class SaleChargeTypeService extends GIPIAbstractCrudService<SaleChargeType, SaleChargeTypeFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.chargeType, 'v1', baseService);
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, ignoreTypes?: SaleTypeChargeType[], onlyTypes?: SaleTypeChargeType[]): Observable<GIPIPageModel<T>> {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let sortAux: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            sortAux = `&sort=${sort.field},${sort.direction}`;
        }
        let ignoreTypesAux: string = '';
        if (!ArrayUtil.isEmpty(ignoreTypes)) {
            if (ignoreTypes.length === 1) {
                ignoreTypesAux = `&ignoreType=${ignoreTypes[0]}`;
            } else if (ignoreTypes.length > 1) {
                ignoreTypesAux = `&ignoreType=${ignoreTypes.join('&ignoreType=')}`;
            }
        }

        let onlyTypesAux: string = '';
        if (!ArrayUtil.isEmpty(onlyTypes)) {
            if (onlyTypes.length === 1) {
                onlyTypesAux = `&type=${onlyTypes[0]}`;
            } else if (onlyTypes.length > 1) {
                onlyTypesAux = `&type=${onlyTypes.join('&type=')}`;
            }
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${sortAux}${ignoreTypesAux}${onlyTypesAux}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
