export enum GeneralIcons {
    CASHED_CHECK = 'cashed_check',
    RECEIPT = 'receipt',
    LOCK = 'lock',
    TRASH = 'trash',
    SEARCH = 'search',
    EDIT = 'edit',
    DROPDOWN_MENU = 'dropdown_menu',
    SEND = 'send',
    MONETIZATION = 'monetization',
    CANCEL = 'cancel',
    INFO = 'info',
    PRINT_OUT = 'print_out',
    SALE_DATA = 'sale_data',
    DETAILS = 'details',
    SEND_INVITATION = 'send_invitation',
    PRINT_BANK_SLIP = 'print_bank_slip',
    PRINT_RECEIPT = 'print_receipt',
    PAY_RECEIVE = 'pay_receive',
    RENEGOTIATE = 'renegotiate',
    SELECT_ALL = 'select_all',
    VIEW_ATTACHMENT = 'view_attachment',
    GROUP_ACCOUNTS = 'group_accounts',
    ATTACH = 'attach',
    CANCEL_RECEIPT_PAYMENT = 'cancel_receipt_payment',
    CHECK_ISSUED = 'check_issued',
    CHECK_RECEIVED = 'check_received',
    RECEIVED_CARD = 'received_card',
    GIPI = 'gipi_svg',
    CANCEL_CONCILIATION = 'cancel_conciliation',
    ADD_DIFFERENCE = 'add_difference',
    ADD_NEW_REGISTER = 'add_new_register',
    CONCILIATION = 'conciliation',
    RIGHT_ARROW_CONCILIATION = 'right_arrow_conciliation',
    UNDO_CONCILIATION = 'undo_conciliation',
    SEND_CUSTODY_CHECK_RECEIVED = 'send_custody_check_received',
    COMPENSATE_CHECK_RECEIVED = 'compensate_check_received',
    DEVOLUTION_CHECK_RECEIVED_ONE = 'devolution_check_received_one',
    DEVOLUTION_CHECK_RECEIVED_TWO = 'devolution_check_received_two',
    DEPOSIT_CHECK_RECEIVED = 'deposit_check_received',
    ANTECIPATION = 'antecipation',
    UPLOAD_FILE = 'upload_file',
    EXPORT_FILE = 'export_file',
    DASHBOARD_BILL_PAYABLE = 'dashboard_bill_payable',
    DASHBOARD_BILL_RECEIVABLE = 'dashboard_bill_receivable',
    CHARTS = 'charts',
    CALENDAR = 'calendar',
    EQUAL = 'equal',
    EYE = 'eye',
    EYE_OFF = 'eye_off',
    PERSON_ADDRESS = 'person_address',
    PERSON_EMAIL = 'person_email',
    PERSON_LEGAL_PERSON = 'person_legal_person',
    PERSON_NATURAL_PERSON = 'person_natural_person',
    PERSON_PHONE = 'person_phone',
    PERSON_SOCIAL_NETWORK = 'person_social_network',
    CLIENT_FINANCIAL = 'client_financial',
    PERSON_COMMENTS = 'person_comments',
    CLIENT_FILE = 'client_file',
    BLOCK_CLIENT = 'block_client',
    BILLET_CONFIGURATION = 'billet_configuration',
    INSTRUCTION_TEXT = 'instruction_text',
    AUTH_STARK_BANK = 'auth_stark_bank',
    EXTRACT_BANK_ACCOUNT = 'extract_bank_account',
    REPRESENTATIVE_PROVIDER = 'representative_provider',
    TIPS_SYSTEM = 'tips_system',
    XML_AUTHORIZED_PERSON = 'xml_authorized_person',
    CLIENT_REFERENCE = 'client_reference',
    CLIENT_DISCOUNT_PRODUCT = 'client_discount_product',
    SALES_MANAGER = 'sales_manager',
    SELLER = 'seller',
    PRODUCT_BARCODE = 'product_barcode',
    PRODUCT_APPLICATION = 'product_application',
    PRODUCT_BATCH = 'product_batch',
    PRODUCT_EXPEDITION_COMPOSITION = 'product_expedition_composition',
    PRODUCT_SALE_COMPOSITION = 'product_sale_composition',
    PRODUCT_GRID = 'product_grid',
    REFRESH = 'refresh',
    TRANSPORTER_VEHICLE = 'transporter_vehicle',
    PRODUCT_CODE_ANP = 'product_code_anp',
    PRODUCT_CODE_BALANCE = 'product_code_balance',
    PRODUCT_WEIGHT = 'product_weight',
    PRODUCT_TAX_DATA = 'product_tax_data',
    XLSX = 'xlsx',
    WHATSAPP = 'whatsapp',
    REPORT_CLIENT_POTENTIAL = 'report_client_potential',
    REPORT_CLIENT_RECURRENT = 'report_client_recurrent',
    REPORT_CLIENT_REGULAR = 'report_client_regular',
    REPORT_CLIENT_TOP_TEN = 'report_client_top_ten',
    REPORT_CLIENT_BIRTHDAY = 'report_client_birthday',
    PRODUCT_TOP_FIVE = 'product_top_five',
    PANEL_INTEGRATION_CONNECT = 'panel_integration_connect',
    PANEL_INTEGRATION_SCANNTECH = 'panel_integration_scanntech',
    PANEL_INTEGRATION_TWILIO = 'panel_integration_twilio',
    SCANNTECH_URL = 'scanntech_url',
    UNDER_CONSTRUCTION = 'under_construction',
    WPP_SELECT_MESSAGES = 'wpp_select_messages',
    CREDIT_ANALYSIS = 'credit_analysis',
    CREDIT_ANALISYS_COST_APPLIED = 'credit_analisys_cost_applied',
    TIMER = 'timer',
    INTEGRATION_WHATSAPP = 'integration_whatsapp',
    INTEGRATION_CONNECT = 'integration_connect',
    INTEGRATION_SCANNTECH = 'integration_scanntech',
    TAB_SCANNTECH_DAILY_CLOSING = 'tab_scanntech_daily_closing',
    TAB_SCANNTECH_PROMOTIONS_CONSULTATION = 'tab_scanntech_promotions_consultation',
    TAB_SCANNTECH_SALE_SHIPPING = 'tab_scanntech_sale_shipping',
    TAB_SCANNTECH_SHIPPING_HISTORY = 'tab_scanntech_shipping_history',
    CLONE = 'clone'
}

export enum MenuIcons {
    DASHBOARD = 'dashboard',
    REGISTER = 'register',
    BILL_RECEIVABLE = 'bill_receivable',
    BILL_PAYABLE = 'bill_payable',
    RECEIVEMENT_CARD = 'receivement_card',
    DEVOLUTION = 'devolution',
    BANK_SLIP = 'bank_slip',
    BILL_CONCILIATION = 'bill_conciliation',
    BANK_ACCOUNT_TRANSFER = 'bank_account_transfer',
    CHECK_MENU = 'check_menu',
    REPORT = 'report',
    INTEGRATION = 'integration',
    CONFIGURATION = 'configuration',
    INTEGRATIONS_INVOICE = 'integrations_invoice',
}

export enum NewIcons {
    COMPANY = 'company',
    NOVELTIES = 'novelties',
    NOTIFICATION = 'notification',
    PERSON = 'person',
    LOGOUT = 'logout',
    MAXIMIZABLE = 'maximizable',
    FILTER = 'filter',
    WIDGET_CHAT = 'widget_chat'
}
